import { useEffect } from 'react'
import request from 'axios'
import { Layout, Menu, Popconfirm, Popover } from 'antd'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { createFromIconfontCN } from '@ant-design/icons'
import { useState } from 'react'
import '../../index.css'
const { Header, Content, Sider } = Layout
export default function MyLayout() {
  const history = useHistory()
  const location = useLocation()
  const pathname = location.pathname
  const [data, setData] = useState([])
  const [userInfo, setUserInfo] = useState({})
  const token = localStorage.getItem('token')
  const company = localStorage.getItem('compId')
  console.log(token, company)
  const datas = []
  console.log(datas)
  console.log(process.env.REACT_APP_BASE_URL_SYS)
  console.log(process.env.REACT_APP_BASE_URL)
  useEffect(() => {
    const fatchInfo = () => {
      request
        .get(`${process.env.REACT_APP_BASE_URL}cas/user/info/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(({ data }) => {
          console.log(data)
          setUserInfo(data.user)
        })
    }
    const fatchMenu = () => {
      return request
        .get(`${process.env.REACT_APP_BASE_URL}cas/user/menu/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(({ data }) => {
          console.log(data)
          setData(data)
          // datas = data
        })
    }
    fatchMenu()
    fatchInfo()
  }, [])

  const confirm = () => {
    request
      .post(
        `${process.env.REACT_APP_BASE_URL_SYS}api/sys/logout/`,
        {
          redirect: window.location.origin + window.location.pathname,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res)
        if (res.data.data.status === 'redirect') {
          window.location.href = res.data.data.logout_url
        }
      })
    localStorage.removeItem('token')
    localStorage.removeItem('compId')
    // window.location.reload()
  }

  const to = (item) => {
    console.log(item)
    if (item.menu_url) {
      history.replace(item.menu_url)
    } else {
      window.location.href = item.menu_url
    }
  }

  const MyIcon = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_3250468_8mhcsrez8u.js',
  })

  const initMenu = (list, isChildren) => {
    list = list.map((item) => {
      if (item.children && item.children.length > 0) {
        console.log(111111111)
        return (
          <Menu.SubMenu
            // icon={item.icon}
            icon={
              isChildren ? null : item.icon ? (
                <img
                  style={{
                    width: 16,
                    height: 16,
                  }}
                  src={item.icon}
                />
              ) : (
                <MyIcon type="icon-zhinengzhuanxie18x18" />
              )
            }
            title={item.menu_name}
            key={item.menu_url}
          >
            {initMenu(item.children, true)}
          </Menu.SubMenu>
        )
      }

      return (
        <Menu.Item
          key={item.menu_url}
          icon={
            isChildren ? null : item.icon ? (
              <img
                style={{
                  width: 16,
                  height: 16,
                }}
                src={item.icon}
              />
            ) : (
              <MyIcon type="icon-zhinengzhuanxie18x18" />
            )
          }
        >
          <Link onClick={() => to(item)}>{item.menu_name}</Link>
        </Menu.Item>
      )
    })
    return list
  }

  return (
    <Layout className="my-layout">
      <Sider
        trigger={null}
        width={216}
        style={{
          background: 'linear-gradient(223deg, #006bb3 0%, #15d2d6 100%)',
        }}
        className="site-layout-background"
        collapsible
        // collapsed={collapsed}
      >
        <Menu
          mode="inline"
          style={{ height: '100%', borderRight: 0 }}
          selectedKeys={[pathname]}
          defaultOpenKeys={['/home/sysmanage']}
        >
          {/* 遍历路由 */}
          {initMenu(data)}
        </Menu>
      </Sider>

      <Layout>
        <Header>
          <span className="left-title">产品Demo系统</span>

          <>
            <div className="ant-header-right">
              <img
                width={40}
                height={40}
                style={{ borderRadius: '50%' }}
                // src={photo}
                alt=""
              />
              <span className="sys-pepo">{userInfo?.username}</span>

              <Popconfirm
                placement="topRight"
                title="你确定要退出吗？"
                onConfirm={confirm}
                okText="确定"
                cancelText="取消"
              >
                <a>退出</a>
              </Popconfirm>

              <span>
                <MyIcon type="icon-a-guangbo1"></MyIcon>
              </span>
              <span className="setting">
                <MyIcon type="icon-a-icon_shezhi1"></MyIcon>
              </span>
              <span>
                <MyIcon type="icon-a-icon_yiwenkongxin1"></MyIcon>
              </span>
            </div>
          </>
        </Header>
      </Layout>
    </Layout>
  )
}
