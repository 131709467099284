// 导入路由
import { Router, Route, Redirect, Switch } from 'react-router-dom'

// 导入页面组件
import Login from './pages/Login'
import Layout from './pages/Layout'
import PrivateRoute from './PrivateRoute'
import history from './history'
// 配置路由规则
function App() {
  return (
    <Router history={history}>
      <Switch>
        <Redirect exact from="/" to="/home"></Redirect>
        <PrivateRoute path="/home" component={Layout}></PrivateRoute>
        <Route path="/login" component={Login}></Route>
      </Switch>
    </Router>
  )
}

export default App
