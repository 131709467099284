import request from 'axios'
import { useEffect } from 'react'
import { useHistory } from 'react-router'

export default function Login() {
  const history = useHistory()
  console.log(process.env.REACT_APP_BASE_URL_SYS)
  console.log(process.env.REACT_APP_BASE_URL)
  useEffect(() => {
    let url = document.location.href
    console.log(url)
    let ticket = ''
    if (/ticket=(ST-[^#/?]+)/.test(url)) {
      ticket = /ticket=(ST-[^#/?]+)/.exec(url)[1]
    }
    request
      .get(`${process.env.REACT_APP_BASE_URL_SYS}api/sys/login/`, {
        params: {
          ticket,
          redirect: url,
        },
      })
      .then((res) => {
        console.log(res)
        if (res.data.data.status === 'redirect') {
          window.location.href = res.data.data.login_url
        } else if (res.data.data.status === 'login') {
          localStorage.setItem('token', res.data.data.token)
          localStorage.setItem('compId', res.data.data.company)
          history.push('/home')
        }
      })
  }, [history])

  return <div></div>
}
